<template>
    <div class="flex flex-row gap-3">
        <div class="basis-1/4">
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512">
                <path fill="currentColor"
                    d="M319 35.375c-27.305-.247-52.415 19.47-62.063 46.094c-9.726-26.856-35.196-46.656-62.78-46.064c-15.11.325-30.85 6.764-45 21.532c-11.47 11.968-17.863 27.782-24.407 43.75c-15 22.49-35.066 39.27-64.156 45.874c7 6.374 14.63 9.69 22.656 11.063c-14.192 7.828-33.583 12.154-61.28 10.78c20.528 26.327 52.643 35.282 86.28 34.532c60.716-2.34 91.448-33.326 109.438-64.875l16.218 9.25c-20.387 35.754-59.15 71.513-127.28 74.094v.72c0 6.067 1.32 13.4 3.686 21.686l-.25-.468l29.5 114.875l4.625-38.626c17.095 31.46 37.67 64.792 56.532 93.97l1.624-46.127c5.83 38.514 20.68 73.426 39.062 106.97c9.337 13.507 15.25 21.78 15.25 21.78s6.953-9.693 17.875-25.562c.07-.1.152-.213.22-.313c20.76-32.818 38.056-67.256 46.438-105.906l-1.063 37.438c9.342-14.666 18.923-30.088 28.156-45.656v28.468l40.533-104.125c9.877-21.585 16.512-40.562 17.656-54.342c-65.713-7.917-111.206-41.86-132.314-78.875l16.22-9.25c19.702 34.552 66.54 68.93 138.312 71.062c15.085-.3 29.905-3.623 45.593-11.313c-14.823.118-27.478-1.08-38.53-3.437c23.34-5.136 43.576-16.795 56.125-37.97c-102.54 21.26-90.343-61.02-127.188-99.468c-14.37-14.994-30.37-21.424-45.687-21.562z">
                </path>
            </svg>
        </div>
        <div class="basis-3/4"><h1 class="text-2xl font-sans font-medium subpixel-antialiased">SebestenyB</h1></div>
    </div>
</template>